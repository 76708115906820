import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';

const Navigation = () => {
  return (
    <AppBar>
      <Toolbar>Sugar Plum Software Solutions</Toolbar>
    </AppBar>
  );
};

export default Navigation;
