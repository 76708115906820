import React from 'react';
import styled from 'styled-components';
import logo512 from '../../resources/imgs/logo512.png';

const FullScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  left: 0;
`;

const LogoContainer = styled.div`
  margin-top: 100px;
`;

const DescriptionContainer = styled.div`
  margin-top: 10px;
`;

const DescriptionText = styled.h3`
  font-style: italic;
`;

const HomePage = () => (
  <FullScreen>
    <LogoContainer>
      <img src={logo512} alt="Sugar Plum Software Solutions Logo" />
    </LogoContainer>
    <DescriptionContainer>
      <DescriptionText>Website under construction.</DescriptionText>
    </DescriptionContainer>
  </FullScreen>
);

export default HomePage;
