import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { Navigation, Spinner } from './common';
import { HomePage } from './home';
import { PageNotFound } from '../components';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const routes = (
    <>
      <CssBaseline />
      <Navigation />
      <div className="container-fluid container-fullscreen">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </>
  );

  return isLoading ? <Spinner /> : routes;
};

export default App;
