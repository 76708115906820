import React from 'react';

const PageNotFound = () => (
  <h1 style={{ padding: '5vh' }}>
    Oops! Something went terribly wrong...
    <br />
    Build a bunker or move to Canada immediately.
  </h1>
);

export default PageNotFound;